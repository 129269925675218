export default class IntlHelper {
  private readonly timezone: string;
  private readonly locale: string;

  constructor(timezone: string, locale: string) {
    this.timezone = timezone;
    this.locale = locale;
  }

  private createIntl(options?: Intl.DateTimeFormatOptions) {
    return new Intl.DateTimeFormat(this.locale, {
      ...options,
      timeZone: this.timezone,
    });
  }

  // example method
  public formatDate(date: Date, options?: Intl.DateTimeFormatOptions) {
    return this.createIntl(options).format(date);
  }
}
