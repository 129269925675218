import { useEffect, useState } from 'react';
import { ApplicationContext, ApplicationProps } from '../useApplicationContext';
import { ApplicationStatus } from '../../../types';
import IntlHelper from '../../helpers/IntlHelper';

export const useInitializeApplicationContext = (
  applicationProps: ApplicationProps,
): ApplicationContext => {
  const { actions, status, selectedTimezone, businessInfo } = applicationProps;
  const { timezone } = selectedTimezone || {};
  const { dateRegionalSettingsLocale } = businessInfo || {};

  const [intlHelper, setIntlHelper] = useState<IntlHelper>();

  useEffect(() => {
    if (status === ApplicationStatus.INITIALIZING) {
      actions.initializeWidget();
    }
  }, [status]);

  useEffect(() => {
    if (timezone && dateRegionalSettingsLocale) {
      setIntlHelper(new IntlHelper(timezone, dateRegionalSettingsLocale));
    }
  }, [timezone, dateRegionalSettingsLocale]);

  return { ...applicationProps, intlHelper };
};
