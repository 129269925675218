import React, { FC } from 'react';
import {
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';
import settingsParams from '../settingsParams';
import {
  ApplicationContextProvider,
  ApplicationProps,
} from '../../../core/hooks/useApplicationContext';
import { DataHooks } from '../../../consts/dataHooks';
import { useInitializeApplicationContext } from '../../../core/hooks/useInitializeApplicationContext';

const Widget: FC<WidgetProps<ApplicationProps>> = (applicationProps) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const applicationContext = useInitializeApplicationContext(applicationProps);

  return (
    <ApplicationContextProvider
      value={applicationContext}
      key="my-bookings-main-widget"
    >
      <div
        className={st(classes.root, {})}
        data-hook={DataHooks.MyBookingsMainWidget}
      >
        <div className={classes.header}>
          <h2 data-hook="app-title">
            {t('app.widget.welcome')}{' '}
            {settings.get(settingsParams.greetingsText)}!
          </h2>
        </div>
        {/* {This is a dummy experiment. To generate a new experiment,
            check this guide: https://github.com/wix-private/fed-handbook/blob/master/EXPERIMENTS.md#create-experiment} */}
        {experiments.enabled('specs.test.ShouldHideButton') ? null : (
          <Button className={classes.mainButton}>Click me</Button>
        )}
      </div>
    </ApplicationContextProvider>
  );
};

export default Widget;
