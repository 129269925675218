import React from 'react';
import { ApplicationState } from '../../../types';
import { ControllerActions } from '../../../actions/actions';
import IntlHelper from '../../helpers/IntlHelper';

export type ApplicationContext = {
  intlHelper?: IntlHelper;
} & ApplicationProps;

export type ApplicationProps = {
  actions: ControllerActions;
} & ApplicationState;

const ApplicationComponentContext = React.createContext<ApplicationContext>(
  {} as ApplicationContext,
);
export const ApplicationContextProvider = ApplicationComponentContext.Provider;

export const useApplicationContext = () =>
  React.useContext(ApplicationComponentContext);
